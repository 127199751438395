import React, { useState } from 'react';
import logoImg from "../assets/img/learnshop.png";
import userService from '../../connections/user';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Navbar = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));

  const handleLogout = async () => {
    try {
     // Get token from localStorage (or cookies)
    const token = localStorage.getItem('token');

    // Call backend logout (send token)
    await userService.logout(token);
      
      // Clear frontend session
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      
      // Show success message
      toast.success('Logged out successfully!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      
      // Redirect after delay
      setTimeout(() => {
        window.location.href = '/login';
      }, 1000);
      
    } catch (error) {
      toast.error('Logout failed. Please try again.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <>
    <ToastContainer />
    <nav className="navbar navbar-expand-md navbar-shrink py-3 bg-info" id="mainNav">
      <div className="container">
        {/* Animated Logo/Brand */}
        <a className="navbar-brand d-flex align-items-center logo-hover-animate" href="/">
          <div className="logo-img-wrapper me-2">
            <img 
              src={logoImg} 
              alt="Brand Logo" 
              width="40" 
              height="40" 
              className="logo-img"
            />
          </div>
          <span className="logo-text">
            <span className="logo-le" style={{ color: "#d87e07", fontWeight: "bold" }}>Le</span>
            <span className="logo-earn text-white font-bold">Earn</span>
          </span>
        </a>
  
        {/* Mobile Toggle */}
        <button
          data-bs-toggle="collapse"
          className="navbar-toggler"
          data-bs-target="#navcol-1"
          style={{ borderColor: "#d87e07" }}
        >
          <span className="visually-hidden text-dark">Toggle navigation</span>
          <span className="navbar-toggler-icon" style={{
            filter: "invert(47%) sepia(86%) saturate(728%) hue-rotate(10deg) brightness(95%) contrast(90%)",
          }}></span>
        </button>
  
        {/* Navigation Content */}
        <div className="collapse navbar-collapse" id="navcol-1">
          <div className="mx-auto"></div>
  
          {/* Buy/Sell Buttons */}
          <div className="d-flex gap-3 me-3">
            <button 
              className="btn btn-success position-relative overflow-hidden hover-scale"
              onClick={() => window.location.href = "/buyproduct"}
            >
              <span className="hover-scale-inner">
                <i className="bi bi-cart-plus me-2"></i> Buy
              </span>
            </button>
  
            <button 
              className="btn btn-warning position-relative overflow-hidden hover-scale"
              onClick={() => window.location.href = "/sellproduct"}
            >
              <span className="hover-scale-inner">
                <i className="bi bi-currency-dollar me-2"></i> Sell
              </span>
            </button>
          </div>
  
          {/* Preserved User Login Features - Exactly as in original */}
          {user ? (
            <div className="d-flex align-items-center gap-3">
              {/* Message Inbox Icon */}
              <div className="position-relative inbox-icon">
                <button 
                  className="btn btn-link p-0 hover-scale"
                  onClick={() => {/* message inbox function */}}
                >
                  <span className="hover-scale-inner">
                    <i className="bi bi-envelope-fill text-white fs-5"></i>
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      3 {/* Replace with actual message count */}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </span>
                </button>
              </div>
              
              {/* User Dropdown */}
              <div className="dropdown">
                <button
                  className="btn btn-outline-light dropdown-toggle hover-scale"
                  type="button"
                  id="userDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="hover-scale-inner">
                    {user.firstName}
                  </span>
                </button>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                  <li>
                    <a className="dropdown-item" href="/userprofile">
                      Profile
                    </a>
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={handleLogout}>
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <button 
              className="btn btn-primary shadow hover-scale"
              onClick={() => window.location.href = "/login"}
              style={{ 
                background: "linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)",
                border: "none"
              }}
            >
              <span className="hover-scale-inner text-white">
                <i className="bi bi-box-arrow-in-right me-2"></i> Sign In
              </span>
            </button>
          )}
        </div>
      </div>
    </nav>
  
    {/* Animation CSS */}
    <style jsx>{`
      /* Logo animations */
      .logo-hover-animate {
        transition: transform 0.4s ease;
      }
      .logo-hover-animate:hover {
        transform: translateY(-2px);
      }
      .logo-img-wrapper {
        transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
      }
      .logo-hover-animate:hover .logo-img-wrapper {
        transform: rotate(-15deg) scale(1.1);
      }
      .logo-img {
        transition: filter 0.3s ease;
      }
      .logo-hover-animate:hover .logo-img {
        filter: drop-shadow(0 2px 4px rgba(0,0,0,0.2));
      }
      .logo-le, .logo-earn {
        display: inline-block;
        transition: transform 0.3s ease;
      }
      .logo-earn {
        transition-delay: 0.05s;
      }
      .logo-hover-animate:hover .logo-le {
        transform: translateY(-2px);
        color: #ff9a00 !important;
      }
      .logo-hover-animate:hover .logo-earn {
        transform: translateY(-2px);
        text-shadow: 0 2px 4px rgba(0,0,0,0.1);
      }
  
      /* Button animations */
      .hover-scale {
        transition: all 0.3s ease;
        transform: translateY(0);
      }
      .hover-scale:hover {
        transform: translateY(-3px);
        box-shadow: 0 5px 15px rgba(0,0,0,0.1);
      }
      .hover-scale-inner {
        transition: transform 0.3s ease;
      }
      .hover-scale:hover .hover-scale-inner {
        transform: scale(1.05);
      }

      /* Inbox icon animations */
      .inbox-icon .hover-scale:hover .hover-scale-inner i {
        animation: bellRing 0.5s ease;
      }
      @keyframes bellRing {
        0% { transform: rotate(0deg); }
        25% { transform: rotate(15deg); }
        50% { transform: rotate(-15deg); }
        75% { transform: rotate(10deg); }
        100% { transform: rotate(0deg); }
      }
      .inbox-icon .badge {
        transition: transform 0.3s ease, opacity 0.3s ease;
        opacity: 0.9;
      }
      .inbox-icon .hover-scale:hover .badge {
        transform: translate(-50%, -50%) scale(1.2);
        opacity: 1;
      }
    `}</style>
  </>
  );
};

export default Navbar;