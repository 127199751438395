import React from "react";
import headerImg from "../assets/img/posters/p1.png";
import leearnpromo from "../assets/videos/leearnpromo.mp4"
const Header = () => {
  return (
    <header className="bg-info text-dark ">
      <div className="container pt-6 pt-xl-5">
        <div className="row pt-5">
          <div className="col-md-8 text-center text-md-start mx-auto">
            <div className="mb-3 pt-5">
              <div className="row g-2 shadow-lg">
                <div className="col-md-6">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="bi bi-geo-alt-fill" style={{ color: "red" }}></i> {/* Location icon */}
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      name="location"
                      placeholder="Enter location"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="bi bi-search" style={{ color: "red" }}></i> {/* Search icon */}
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      name="product"
                      placeholder="Find a service"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <p className="text-danger">This is a protype website!</p>
              <h1 className="display-4 fw-bold mb-5">
              Find the Best College Deals Near You!&nbsp;
                <span className="underline"></span>
              </h1>
              <p className="fs-5 mb-5">
              "A dedicated reselling platform for students to trade books, electronics, and everyday essentials. Buy smart, sell fast, and make the most of student life!"
              </p>
              <form
                className="d-flex justify-content-center flex-wrap"
                method="post"
                data-bs-theme="light"
              ></form>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
