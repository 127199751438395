import api from './connect';
const productService = {

    //add product
    addProduct: async (formData) => {
        try {
           // Log the FormData for debugging
           console.log('FormData before sending:',formData.get('image')); // Log the FormData
          const response = await api.put('/products/addProduct', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          return response.data;
        } catch (error) {
          console.error('Error updating user:', error);
          throw error;
        }
      },

    // Get all products

    getAllProducts: async () => {
        try {
          
          const response = await api.get('/products/getAllProducts', {
            headers: {
              'Accept': 'application/json'
            }
          });
          console.log('Response data:', response.data); 
          return response.data;
        } catch (error) {
          console.error('Error fetching products:', error);
          throw error;
        }
      }

}
export default productService;