import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaSpinner, FaCheck, FaCamera } from "react-icons/fa";
import productService from "../connections/product";

const ProductForm = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [product, setProduct] = useState({
    name: "",
    price: "",
    details: "",
    location: "",
    description: "",
    productImage: null,
  });

  // Check authentication on component mount
  useEffect(() => {
    const checkAuth = () => {
      const userData = JSON.parse(localStorage.getItem("user"));
      if (!userData) {
        navigate("/login");
      } else {
        setIsAuthenticated(true);
      }
    };

    checkAuth();
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProduct((prev) => ({ ...prev, image: file }));

      // Create preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const formData = new FormData();

      // Append product data
      formData.append("name", product.name);
      formData.append("price", product.price);
      formData.append("details", product.details);
      formData.append("location", product.location);
      formData.append("description", product.description);

      // Append image if exists
      if (product.image) {
        formData.append("productImage", product.image);
      }

      // Get userId from localStorage
      const userData = JSON.parse(localStorage.getItem("user"));
      if (userData && userData.id) {
        formData.append("userId", userData.id);
      } else {
        throw new Error("User not authenticated");
      }

      // Send to backend
      await productService.addProduct(formData);

      // Show success and redirect
      setShowSuccess(true);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      console.error("Error adding product:", error);
      alert("Failed to add product. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isAuthenticated) {
    return null; // Or a loading spinner while redirect happens
  }

  return (
    <div className="container-fluid  min-vh-100 py-5">
      {showSuccess && (
        <div
          className="position-fixed top-50 start-50 translate-middle"
          style={{
            zIndex: 9999,
          }}
        >
          <div
            className="bg-white rounded-circle p-3 shadow-sm"
            style={{
              animation: "zoomIn 0.3s ease-out",
              transformOrigin: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "80px",
              height: "80px",
            }}
          >
            <FaCheck
              className="text-success"
              style={{
                fontSize: "2.5rem",
                animation: "checkmarkDraw 0.5s ease-out 0.3s both",
              }}
            />
          </div>
          {/* Animation definitions */}
          <style>
            {`
        @keyframes zoomIn {
          0% {
            transform: scale(0);
            opacity: 0;
          }
          100% {
            transform: scale(1);
            opacity: 1;
          }
        }
        @keyframes checkmarkDraw {
          0% {
            stroke-dashoffset: 50;
            opacity: 0;
          }
          100% {
            stroke-dashoffset: 0;
            opacity: 1;
          }
        }
      `}
          </style>
        </div>
      )}

      <div className="container">
        {/* Animated Back Button */}
        <button
          className="btn btn-link text-decoration-none position-absolute start-0 ms-4 mt-3"
          onClick={() => navigate("/")}
          style={{ transition: "transform 0.3s ease" }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.transform = "translateX(-5px)")
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.transform = "translateX(0)")
          }
        >
          <FaArrowLeft className="me-2" />
          Back to Home
        </button>

        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="card shadow-sm border-0">
              <div className="card-body p-4 p-md-5 bg-white">
                <h2 className="text-center mb-4">Add New Product</h2>

                <form onSubmit={handleSubmit}>
                  {/* Image Upload Section */}
                  <div className="mb-4 text-center">
                    <div
                      className={`border rounded-3 p-4 mb-3 ${
                        previewImage ? "border-success" : "border-dashed"
                      }`}
                      style={{
                        cursor: "pointer",
                        height: "250px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                        backgroundColor: previewImage
                          ? "transparent"
                          : "#f8f9fa",
                      }}
                      onClick={triggerFileInput}
                    >
                      {previewImage ? (
                        <img
                          src={previewImage}
                          alt="Preview"
                          className="img-fluid h-100"
                          style={{ objectFit: "contain" }}
                        />
                      ) : (
                        <>
                          <FaCamera
                            className="text-muted mb-2"
                            style={{ fontSize: "2rem" }}
                          />
                          <p className="mb-1 fw-bold">
                            Click to upload product image
                          </p>
                          <small className="text-muted">
                            Recommended size: 800x600px
                          </small>
                        </>
                      )}
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleImageChange}
                      accept="image/*"
                      className="d-none"
                    />
                  </div>

                  {/* Form Fields */}
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Product Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={product.name}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="price" className="form-label">
                      Price ($)
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="price"
                      name="price"
                      value={product.price}
                      onChange={handleChange}
                      min="0"
                      step="0.01"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="details" className="form-label">
                      Product Details
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="details"
                      name="details"
                      value={product.details}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="location" className="form-label">
                      Location
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="location"
                      name="location"
                      value={product.location}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      value={product.description}
                      onChange={handleChange}
                      rows="4"
                      required
                    ></textarea>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-success w-100 py-2 fw-bold"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <FaSpinner className="me-2 fa-spin" />
                        Adding Product...
                      </>
                    ) : (
                      "Add Product"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductForm;
