import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import productService from '../../connections/product';
import { FaSpinner, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const TrendProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await productService.getAllProducts();
        setProducts(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setProducts([]); // Ensure empty array if error occurs
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Auto slide every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (products.length > 6) {
        nextSlide();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [currentSlide, products.length]);

  const handleProductClick = (id) => {
    navigate(`/${id}`);
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => 
      prev === Math.ceil(products.length / 6) - 1 ? 0 : prev + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => 
      prev === 0 ? Math.ceil(products.length / 6) - 1 : prev - 1
    );
  };

  // Get current slide products
  const visibleProducts = products.slice(currentSlide * 6, (currentSlide + 1) * 6);

  // Skeleton loader products
  const skeletonProducts = Array(6).fill({});

  return (
    <section className="bg-light py-5 position-relative">
      <div className="container">
        <div className="block-heading text-center mb-5">
          <h2 className="text-dark">Trending Products</h2>
        </div>

        <div className="row mx-0" ref={sliderRef}>
          {(loading ? skeletonProducts : visibleProducts).map((product, index) => (
            <div className="col-md-2 col-6 mb-4 px-2" key={loading ? `skeleton-${index}` : product._id}>
              <div 
                className="card product-card h-100"
                onClick={() => !loading && product._id && handleProductClick(product._id)}
                style={{
                  cursor: loading ? 'default' : 'pointer',
                  animation: loading ? 'none' : 'blink 3s infinite',
                  transition: 'all 0.3s ease',
                  border: 'none',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }}
                onMouseEnter={(e) => !loading && (e.currentTarget.style.transform = 'translateY(-5px)')}
                onMouseLeave={(e) => !loading && (e.currentTarget.style.transform = 'translateY(0)')}
              >
                <div 
                  className="card-img-top-container bg-secondary" 
                  style={{ 
                    height: '120px', 
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {loading ? (
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                      <FaSpinner className="fa-spin text-light" />
                    </div>
                  ) : (
                    <img 
                      src={product.image?.url || '/images/default-product.png'} 
                      className="card-img-top h-100 w-100 object-fit-cover" 
                      alt={product.name}
                    />
                  )}
                </div>
                <div className="card-body p-2">
                  <h6 className="card-title mb-1" style={{ fontSize: '0.9rem' }}>
                    {loading ? (
                      <div className="placeholder-glow">
                        <span className="placeholder col-8"></span>
                      </div>
                    ) : product.name}
                  </h6>
                  <h6 className="text-primary mb-1" style={{ fontSize: '0.9rem' }}>
                    {loading ? (
                      <div className="placeholder-glow">
                        <span className="placeholder col-4"></span>
                      </div>
                    ) : `$${product.price}`}
                  </h6>
                  <p className="card-text text-muted small" style={{ fontSize: '0.8rem' }}>
                    {loading ? (
                      <div className="placeholder-glow">
                        <span className="placeholder col-12"></span>
                        <span className="placeholder col-10"></span>
                      </div>
                    ) : (
                      product.details?.length > 50 ? `${product.details.substring(0, 50)}...` : product.details
                    )}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Navigation arrows at bottom */}
        {!loading && products.length > 6 && (
          <div className="d-flex justify-content-center mt-3">
            <button 
              onClick={prevSlide}
              className="btn btn-sm btn-outline-primary mx-1"
              style={{ width: '30px', height: '30px', borderRadius: '50%' }}
            >
              <FaChevronLeft size={12} />
            </button>
            <button 
              onClick={nextSlide}
              className="btn btn-sm btn-outline-primary mx-1"
              style={{ width: '30px', height: '30px', borderRadius: '50%' }}
            >
              <FaChevronRight size={12} />
            </button>
          </div>
        )}

        {/* Empty state */}
        {!loading && products.length === 0 && (
          <div className="text-center py-4">
            <p className="text-muted">No trending products available</p>
          </div>
        )}

        <style>
          {`
            @keyframes blink {
              0%, 100% { box-shadow: 0 0 10px rgba(0,0,0,0.1); }
              50% { box-shadow: 0 0 15px rgba(33, 150, 243, 0.3); }
            }
            .placeholder {
              background-color: #e9ecef;
              height: 0.8rem;
              display: inline-block;
            }
          `}
        </style>
      </div>
    </section>
  );
};

export default TrendProducts;