import React from 'react';

const Services = () => {
    return (
      <section className="clean-block features py-5 position-relative" style={{background: 'linear-gradient(to bottom, #0dcaf0 0%, rgba(23, 162, 184, 0.3) 80%, #f8f9fa 100%)'}}>
      <div className="container position-relative">
        <div className="block-heading text-center mb-5">
          <h2 className="text-light">Student Services</h2>
          <h4 className="text-warning">Campus Marketplace Solutions</h4>
        </div>
        
        {/* QuickSell Campus */}
        <div className="service-row animate-ready">
          <div className="row align-items-center gx-5 mb-5">
            <div className="col-lg-6">
              <div className="p-4">
                <div className="d-flex align-items-center mb-3">
                  <i className="bi bi-lightning-charge-fill text-warning fs-1 me-4"></i>
                  <h3 className="mb-0 ">QuickSell Campus</h3>
                </div>
                <p className="lead text-dark">
                  Fast-track selling for students in a hurry. List your textbooks, electronics, 
                  and dorm essentials quickly with instant buyer matching and rapid payment processing.
                </p>
                <div className="arrow-link">
                  <a href="/quicksell" className="text-warning position-relative">
                    <i className="bi bi-arrow-right blinking-arrow"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <div className="service-image p-4 text-center">
                <img 
                  src="https://cdn-icons-png.flaticon.com/512/1570/1570887.png" 
                  alt="QuickSell" 
                  className="img-fluid floating-anim"
                  style={{maxWidth: '200px'}}
                />
              </div>
            </div>
          </div>
        </div>
    
        {/* FindMyDeal - Reversed */}
        <div className="service-row animate-ready">
          <div className="row align-items-center gx-5 mb-5 flex-lg-row-reverse">
            <div className="col-lg-6">
              <div className="p-4">
                <div className="d-flex align-items-center mb-3">
                  <i className="bi bi-binoculars-fill text-warning fs-1 me-4"></i>
                  <h3 className="mb-0">FindMyDeal</h3>
                </div>
                <p className="lead text-dark">
                  Personalized search alerts for buyers. Get notified when specific items 
                  become available at your target price range. Never miss your perfect campus deal again.
                </p>
                <div className="arrow-link">
                  <a href="/findmydeal" className="text-warning position-relative">
                    <i className="bi bi-arrow-right blinking-arrow"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <div className="service-image p-4 text-center">
                <img 
                  src="https://cdn-icons-png.flaticon.com/512/4156/4156971.png" 
                  alt="FindMyDeal" 
                  className="img-fluid floating-anim"
                  style={{maxWidth: '200px'}}
                />
              </div>
            </div>
          </div>
        </div>
    
        {/* SafeSwap Hub */}
        <div className="service-row animate-ready">
          <div className="row align-items-center gx-5 mb-5">
            <div className="col-lg-6">
              <div className="p-4">
                <div className="d-flex align-items-center mb-3">
                  <i className="bi bi-shield-lock-fill text-warning fs-1 me-4"></i>
                  <h3 className="mb-0">SafeSwap Hub</h3>
                </div>
                <p className="lead text-dark">
                  Verified campus meetup spots with security cameras and secure payment options. 
                  Our escrow system holds funds until both parties confirm a successful exchange.
                </p>
                <div className="arrow-link">
                  <a href="/safeswap" className="text-warning position-relative">
                    <i className="bi bi-arrow-right blinking-arrow"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <div className="service-image p-4 text-center">
                <img 
                  src="https://cdn-icons-png.flaticon.com/512/2452/2452566.png" 
                  alt="SafeSwap Hub" 
                  className="img-fluid floating-anim"
                  style={{maxWidth: '200px'}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <style jsx>{`
        /* Initial visible state */
        .animate-ready {
          opacity: 1;
          transform: translateY(0);
        }
        
        /* Floating animation for icons */
        .floating-anim {
          animation: floating 3s ease-in-out infinite;
        }
        
        @keyframes floating {
          0% { transform: translateY(0px); }
          50% { transform: translateY(-15px); }
          100% { transform: translateY(0px); }
        }
        
        /* Arrow Link Styling */
        .arrow-link {
          margin-top: 1.5rem;
        }
        
        .blinking-arrow {
          font-size: 2rem;
          animation: blink 1.5s infinite, bounce 1s infinite alternate;
          display: inline-block;
          color: #ffc107;
        }
        
        @keyframes blink {
          0%, 100% { opacity: 1; }
          50% { opacity: 0.5; }
        }
        
        @keyframes bounce {
          from { transform: translateX(0); }
          to { transform: translateX(10px); }
        }
        
        .arrow-link a:hover .blinking-arrow {
          animation: blink 0.5s infinite, bounce 0.5s infinite alternate;
          color: #fff;
        }
    
        /* Optional scroll animation */
        @media (prefers-reduced-motion: no-preference) {
          .animate-on-scroll {
            opacity: 0;
            transform: translateY(30px);
            transition: opacity 0.6s ease-out, transform 0.6s ease-out;
          }
          
          .animate-on-scroll.visible {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </section>
    );
};

export default Services;