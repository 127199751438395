import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import userService from "../connections/user";
import "bootstrap/dist/css/bootstrap.min.css";
import logoImg from "../components/assets/img/learnshop.png";

const LoginPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await userService.login(formData);
      console.log("Login data:", response.user.profileImage.url);
      localStorage.setItem("token", response.token);
      localStorage.setItem("user", JSON.stringify(response.user));

      navigate("/");
    } catch (err) {
      setError(
        err.response?.data?.message || "Login failed. Please try again."
      );
      console.error("Login error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center bg-light">
      <div className="col-12 col-md-6 col-lg-4">
        <div className="card shadow-sm bg-white">
          {/* Back arrow with blinking animation */}
          <div className="position-absolute top-0 start-0 p-2 -">
            <a
              href="/"
              className="text-decoration-none back-arrow blink-animation text-danger "
              style={{ fontSize: "1.7rem" }}
            >
              &larr;
            </a>
          </div>

          {/* Logo with animation at the top */}
          <div className="text-center py-4 logo-container">
            <a href="/" className="d-inline-block logo-hover-animate">
              <div className="logo-img-wrapper d-inline-block me-2">
                <img
                  src={logoImg}
                  alt="Website Logo"
                  width="50"
                  height="50"
                  className="logo-img"
                />
              </div>
              <span className="logo-text">
                <span
                  className="logo-le"
                  style={{ color: "#d87e07", fontWeight: "bold" }}
                ></span>
                <span className="logo-earn text-dark font-bold"></span>
              </span>
            </a>
          </div>

          <div className="card-body px-5 pb-5 pt-0 bg-white">
            <h2 className="card-title text-center text-darkmb-4">Sign in</h2>

            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="d-flex justify-content-between mb-3">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="remember-me"
                  />
                  <label className="form-check-label" htmlFor="remember-me">
                    Remember me
                  </label>
                </div>

                <a
                  href="/forgot-password"
                  className="text-primary text-decoration-none"
                >
                  Forgot password?
                </a>
              </div>

              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Signing in...
                  </>
                ) : (
                  "Sign in"
                )}
              </button>
            </form>

            <div className="text-center mt-3">
              <p className="text-muted">
                Don't have an account?{" "}
                <a href="/signup" className="text-primary">
                  Sign up
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Animation CSS */}
      <style jsx>{`
        .logo-container {
          background-color: white;
        }

        /* Logo animations */
        .logo-hover-animate {
          transition: transform 0.3s ease;
          text-decoration: none;
        }
        .logo-hover-animate:hover {
          transform: translateY(-2px);
        }
        .logo-img-wrapper {
          transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
        }
        .logo-hover-animate:hover .logo-img-wrapper {
          transform: rotate(-15deg) scale(1.1);
        }
        .logo-img {
          transition: filter 0.3s ease;
        }
        .logo-hover-animate:hover .logo-img {
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
        }
        .logo-le,
        .logo-earn {
          display: inline-block;
          transition: transform 0.3s ease;
        }
        .logo-earn {
          transition-delay: 0.05s;
        }
        .logo-hover-animate:hover .logo-le {
          transform: translateY(-2px);
          color: #ff9a00 !important;
        }
        .logo-hover-animate:hover .logo-earn {
          transform: translateY(-2px);
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        /* Back arrow blinking animation */
        .back-arrow {
          color: #6c757d;
          transition: color 0.3s ease;
        }
        .back-arrow:hover {
          color: #0d6efd;
        }
        .blink-animation {
          animation: blink 2s infinite;
        }
        @keyframes blink {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0.3;
          }
          100% {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default LoginPage;
